import "../css/hero.css";
import hero from "../img/marker8.png";
import whatsApp from "../img/whatAppCut.png";
import bgVideo1 from "./video/hero_com.mp4";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";

const Hero = () => {
    const [isVisible, setIsVisible] = useState(false);

    const backgroundImageStyle = {
        background: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.85))',
        borderRadius: "16px",
        position: "relative",
    };

    useEffect(() => {
        document.querySelector('.hero-img').style.opacity = '1';

        const delay = 130;
        const timeoutId = setTimeout(() => {
            setIsVisible(true);
        }, delay);

        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <section className={`hero ${isVisible ? "slide-up active" : "slide-up"}`}>
            <div style={backgroundImageStyle} className="row gap-5 mb-5 p-5 justify-content-center align-items-center">
                <div className="hero-img-marker col-12 col-md-5 col-xl-5">
                    <img className="people" src={hero} alt="taxi ubr" />
                </div>
                <div className="slogan col-12 col-md-5 col-xl-5 text-white">
                    <h1 className="text-start text-white mb-5 text-center" style={{ fontWeight: "bold", fontSize: "2em", letterSpacing: ".065em" }}>
                        &#8220; บริการรถเช่าพร้อมคนขับ &#8221;
                    </h1>
                    <h2 className="text-white mb-5 text-start" style={{ width: "fit-content", margin: "auto" }}>
                        <span className="d-block mb-3 text-center" style={{ fontSize: ".8em" }}>ศูนย์แท็กซี่</span>
                        <ServiceList />
                    </h2>
                    <p className="text-start" style={{ textIndent: "1em", textAlign: "justify" }}>
                        ให้บริการรับส่งสนามบินจองล่วงหน้า ให้บริการนำเที่ยว แนะนำสถานที่ท่องเที่ยวที่น่าสนใจในขอนแก่น และทั่วประเทศ มีรถให้บริการ รถแท็กซี่รถส่วนบุคคล
                        <br /><br />
                        <i>เดินทางไปกับเราสะอาดปลอดภัย มั่นใจทุกเส้นทาง</i>
                    </p>
                </div>
                <div className="hero-img" alt="taxi ubr">
                    <video
                        id="hero-video"
                        loop
                        autoPlay
                        muted
                        playsInline
                        preload="auto"
                        style={{
                            borderRadius: "16px",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            top: 0,
                            left: 0,
                            opacity: 1,
                        }}
                    >
                        <source src={bgVideo1} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            <ContactSection whatsApp={whatsApp} />
        </section>
    );
};

const ServiceList = () => {
    const services = [
        "บริการรถแท็กซี่",
        "แท็กซี่มิเตอร์",
        "เหมารถแท็กซี่",
        "เหมารถตู้",
        "รับส่งทั่วประเทศ"
    ];

    return services.map((service, index) => (
        <span key={index} className="me-3 mb-2 d-inline-block">
            <ServiceIcon /> {service} <br />
        </span>
    ));
};

const ServiceIcon = () => (
    <svg style={{ color: "#22c55e" }} width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
);

const ContactSection = ({ whatsApp }) => (
    <div className="row">
        <div className="col-lg-6">
            <h2 className="text-center fw-bold mb-4">WhatsApp</h2>
            <img className="people" src={whatsApp} alt="taxi ubr whats app"
                style={{
                    maxWidth: "100%",
                    height: '390px',
                    objectFit: "cover",
                    display: 'block',
                    margin: '0 auto',
                    backgroundColor: "black",
                    borderRadius: '5px',
                    padding: "0 4px 4px",
                }}
            />
        </div>
        <div className="col-lg-6" style={{ width: '293px', margin: "40px auto 0" }}>
            <div className="row align-items-center justify-content-center h-100">
                <div>
                    <LineLink href="https://lin.ee/LgJ5lkh" imgSrc="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" imgAlt="เพิ่มเพื่อน" />
                    <PhoneLink />
                    <SocialLink href="https://www.facebook.com/profile.php?id=61556482434476" backgroundColor="#3b5998" iconClass="bi-facebook" text="Facebook Page" />
                </div>
            </div>
        </div>
    </div>
);

const SocialLink = ({ href, imgSrc, imgAlt, backgroundColor = "#00B900", iconClass = "bi-telephone-fill", text = "095-221-5764" }) => (
    <div className="text-center mb-3">
        <a className="text-decoration-none text-white" href={href}>
            <div className="d-flex gap-3 align-items-center justify-content-center h5 mb-0 lh-base p-3" style={{ borderRadius: "8px", height: "98.38px", fontSize: "1.5em", backgroundColor }}>
                <div className="d-flex align-items-center justify-content-center" style={{ width: "40px" }}>
                    <div className="d-flex align-items-center justify-content-center" style={{ width: "55px", height: "45px", backgroundColor: "white", borderRadius: "8px" }}>
                        <i className={`bi ${iconClass}`} style={{ color: backgroundColor }}></i>
                    </div>
                </div>
                <span className="fw-bold h2 mb-0">{text}</span>
            </div>
        </a>
    </div>
);

const PhoneLink = () => (
    <SocialLink href="tel:+66952215764" />
);
const LineLink = () => (
<div className='text-start mb-3'>
<a href="https://lin.ee/LgJ5lkh">
    <img className="w-100 h-auto" src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" alt="เพิ่มเพื่อน" border="0" />
</a>
</div>
);

{/* <div className='text-start mb-3'>
<a href="https://lin.ee/LgJ5lkh">
    <img className="w-100 h-auto" src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" alt="เพิ่มเพื่อน" border="0" />
</a>
</div> */}
    
    export default Hero;
